export const urlToFile = async (url: string) => {
    const response = await fetch(url);
    return new File([await response.blob()], url);
};

export const getCSVDownloadLink = (headers: string[]) => {
    const headerRow = headers.join(",");
    const blob = new Blob([headerRow], { type: "text/csv;charset=utf-8;" });
    return URL.createObjectURL(blob);
};

export enum ResizedDimensions {
    XS = "xs",
    SM = "sm",
    MD = "md",
    LG = "lg",
}

export const withResizedDimensions = (
    url: string,
    dimensions: ResizedDimensions
) => {
    return url.replace("original", dimensions);
};

export const replaceImageSrc = (
    e: React.SyntheticEvent<HTMLImageElement, globalThis.Event>,
    src: string
) => {
    e.currentTarget.src = src;
};
