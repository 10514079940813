import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

export const getAlertTheme = () => {
    const { defineMultiStyleConfig, definePartsStyle } =
        createMultiStyleConfigHelpers(alertAnatomy.keys);

    const container = defineStyle({
        '&[data-status="success"]': {
            bgColor: "rgba(0, 256, 0, .1)",
            color: "white",
        },
        '&[data-status="info"]': {
            bgColor: "rgba(50, 50, 255, .1)",
            color: "white",
        },
        '&[data-status="warning"]': {
            bgColor: "rgba(250, 255, 20, .1)",
            color: "white",
        },
        '&[data-status="error"]': {
            bgColor: "rgba(255, 0, 0, .3)",
            color: "white",
        },
        rounded: "md",
    });

    const icon = defineStyle({
        '&[data-status="success"]': {
            color: "#43A047",
        },
        '&[data-status="warning"]': {
            color: "yellow",
        },
    });

    const baseStyle = definePartsStyle({
        container,
        icon,
    });

    return defineMultiStyleConfig({
        baseStyle,
        variants: {
            toast: {
                container: defineStyle({
                    '&[data-status="success"]': {
                        bgColor: "green.600",
                        color: "white",
                    },
                    '&[data-status="info"]': {
                        bgColor: "blue.600",
                        color: "white",
                    },
                    '&[data-status="warning"]': {
                        bgColor: "yellow.600",
                        color: "white",
                    },
                    '&[data-status="error"]': {
                        bgColor: "red.600",
                        color: "white",
                    },
                    rounded: "md",
                }),
                icon: defineStyle({
                    '&[data-status="success"]': {
                        color: "white",
                    },
                    '&[data-status="warning"]': {
                        color: "white",
                    },
                }),
            },
        },
    });
};
