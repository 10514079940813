import { Country } from "@api/types";
import { useGetMyProfileQuery } from "@api/useCases/users/queries/getMyProfile/useGetMyProfileQuery";
import Cookies from "js-cookie";
import moment, { Moment } from "moment";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../components/LoadingOverlay";

const storageSignIn = (token: string, expiresIn: number) => {
    const currentUtc = moment.utc();
    const expirationDate = currentUtc.add(expiresIn, "seconds");
    storageHandler.set({ token, expirationDate });
};

const storageSignOut = () => {
    storageHandler.set(null);
};

const storageHandler = {
    set: (data: AuthContextProps["auth"] | null) => {
        if (data === null) {
            if (navigator.cookieEnabled) {
                const localData = storageHandler.get();
                if (localData) {
                    Cookies.remove(`${window.location.origin}--auth`, {
                        secure: !import.meta.env.DEV,
                        sameSite: "strict",
                        expires: moment
                            .utc(localData.expirationDate)
                            .diff(moment.utc(), "days"),
                    });
                }
            } else localStorage.removeItem(`${window.location.origin}--auth`);
            return;
        }

        const storedData = {
            token: data.token,
            expirationDate: data.expirationDate.format(),
        };

        if (navigator.cookieEnabled) {
            Cookies.set(
                `${window.location.origin}--auth`,
                JSON.stringify(storedData),
                {
                    secure: !import.meta.env.DEV,
                    sameSite: "strict",
                    expires: moment
                        .utc(data.expirationDate)
                        .diff(moment.utc(), "days"),
                }
            );
            return;
        }

        localStorage.setItem(
            `${window.location.origin}--auth`,
            JSON.stringify(storedData)
        );
    },
    get: () => {
        let data = null;
        if (navigator.cookieEnabled) {
            data = Cookies.get(`${window.location.origin}--auth`);
        } else {
            data = localStorage.getItem(`${window.location.origin}--auth`);
        }

        if (data) {
            const parsedData = JSON.parse(data);
            return {
                token: parsedData.token,
                expirationDate: moment.utc(parsedData.expirationDate),
            };
        }

        return null;
    },
};

interface AuthContextProps {
    user: {
        id: string;
        email: string;
        activeOrganization?: {
            id: string;
            permissions: {
                canEditProfile: boolean;
                canManageEvents: boolean;
                canValidateQrs: boolean;
                canSeeTotalQrsCount: boolean;
            };
        };
        authData: {
            isBusinessOwner: boolean;
            isPublicRelations: boolean;
        };
        createdAt: string;
        createdFirstEvent: boolean;
        country?: Country;
    } | null;
    auth: {
        token: string;
        expirationDate: Moment;
    } | null;
    getIsAuthenticated: () => boolean;
    signOut: () => void;
    signIn: (token: string, expiresIn: number) => void;
}

export const AuthContext = createContext<AuthContextProps>({
    user: null,
    auth: null,
    getIsAuthenticated: () => false,
    signOut: () => null,
    signIn: () => null,
});

export const AuthContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const navigate = useNavigate();
    const [authData, setAuthData] = useState<AuthContextProps["auth"] | null>(
        storageHandler.get()
    );

    const getIsAuthenticated = useCallback(() => {
        if (!authData) return false;

        return moment.utc(authData.expirationDate).isAfter(moment.utc());
    }, [authData]);

    const signIn = useCallback(
        (token: string, expiresIn: number) => {
            storageSignIn(token, expiresIn);
            setAuthData({
                token,
                expirationDate: moment.utc().add(expiresIn, "seconds"),
            });
        },
        [setAuthData]
    );

    const signOut = useCallback(() => {
        storageSignOut();
        setAuthData(null);
    }, [setAuthData]);

    const { isLoading, data: user } = useGetMyProfileQuery(
        authData?.token ?? "", // this should never happen because the query is only called when the user is authenticated
        {
            enabled: getIsAuthenticated(),
            onError: signOut,
        }
    );

    useEffect(() => {
        const id = setInterval(() => {
            if (authData !== null && !getIsAuthenticated()) {
                signOut();
            }
        }, 1000);

        return () => clearInterval(id);
    }, []);

    useEffect(() => {
        if (user && user.country === null) navigate("/auth/set-country");
    }, [user]);

    if (getIsAuthenticated() && isLoading) return <LoadingOverlay />;

    return (
        <AuthContext.Provider
            value={{
                user: user!,
                auth: storageHandler.get(),
                getIsAuthenticated,
                signOut,
                signIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
