import { FeatureEnum } from "@api/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { apiClient } from "../../../../config";

export interface Organization {
    id: string;
    profilePicture: string;
    name: string;
    isOwnedByCurrentUser: boolean;
    permissions: {
        canManageEvents: boolean;
        canEditProfile: boolean;
        canValidateQrs: boolean;
        canSeeTotalQrsCount: boolean;
    };
    premiumCredits: number;
    featureToggles: {
        feature: FeatureEnum;
        enabled: boolean;
    }[];
}

export const useGetOrganizationQuery = (
    orgId: string,
    options?: Omit<UseQueryOptions<AxiosResponse<Organization>>, "queryKey">
) =>
    useQuery(
        [useGetOrganizationQuery.name, orgId],
        () => apiClient.get<Organization>(`/organizations/${orgId}`),
        options
    );
