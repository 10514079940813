import { useTheme } from "@chakra-ui/react";
import Select, { GroupBase, Props } from "react-select";

type ThemedCustomSelectProps<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group>;

const ThemedSelect = <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: ThemedCustomSelectProps<Option, IsMulti, Group>
) => {
    const chakraTheme = useTheme();

    return (
        <Select
            {...props}
            noOptionsMessage={
                props.noOptionsMessage || (() => "No hay opciones")
            }
            styles={
                props.styles || {
                    control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        borderWidth: 0,
                        borderRadius: chakraTheme.radii.md,
                        color: state.hasValue
                            ? "white"
                            : chakraTheme.__cssVars["--chakra-colors-gray-600"],
                        boxShadow: "none",
                    }),
                    input: (provided) => ({
                        ...provided,
                        color: "white",
                    }),
                    indicatorSeparator: () => ({
                        display: "none",
                    }),
                    dropdownIndicator: (provided, state) => ({
                        ...provided,
                        visibility: state.isDisabled ? "hidden" : "visible",
                    }),
                    menu: (provided) => ({
                        ...provided,
                        backgroundColor:
                            chakraTheme.__cssVars["--chakra-colors-gray-700"],
                        zIndex: 10,
                        position: "absolute",
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected
                            ? chakraTheme.colors["dark_purple"]
                            : state.isFocused
                            ? chakraTheme.colors["darker_purple"]
                            : "transparent",
                        color: state.isSelected
                            ? "white"
                            : state.isFocused
                            ? chakraTheme.__cssVars["--chakra-colors-gray-300"]
                            : chakraTheme.__cssVars["--chakra-colors-gray-500"],
                        opacity: state.isDisabled ? 0.5 : 1,
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        color: "white",
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        color: "#718096",
                    }),
                    multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: chakraTheme.colors.primary,
                        color: "white",
                    }),
                    multiValueRemove: (provided) => ({
                        ...provided,
                        backgroundColor: `${chakraTheme.colors.primary} !important`,
                    }),
                }
            }
        />
    );
};
export default ThemedSelect;
