import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { initializeSentry } from "./config/sentry";
import { theme } from "./config/theme/theme";
import { AuthContextProvider } from "./contexts/AuthContext";
import { AuthenticatedAxiosProvider } from "./contexts/AuthenticatedAxiosContext";
import { CurrentOrganizationProvider } from "./contexts/CurrentOrganizationContext";

initializeSentry();

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const queryClient = new QueryClient();
const root = createRoot(container);

root.render(
    <QueryClientProvider client={queryClient}>
        <ChakraProvider
            theme={theme}
            toastOptions={{
                defaultOptions: {
                    duration: 3000,
                    isClosable: true,
                    position: "top",
                    variant: "toast",
                },
            }}
        >
            <BrowserRouter>
                <AuthContextProvider>
                    <AuthenticatedAxiosProvider>
                        <CurrentOrganizationProvider>
                            <App />
                        </CurrentOrganizationProvider>
                    </AuthenticatedAxiosProvider>
                </AuthContextProvider>
            </BrowserRouter>
        </ChakraProvider>
    </QueryClientProvider>
);
